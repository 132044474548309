import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import type { Meta } from "@/__main__/router.mjs";
import { appURLs } from "@/app/constants.mjs";
import DownloadButtonWrapper from "@/app/DownloadButtonWrapper.jsx";
import type { ValorantProfile } from "@/data-models/valorant-profile.mjs";
import DurationPerDay from "@/game-val/components/DurationPerDay.jsx";
import SeasonAgentBackground from "@/game-val/components/SeasonAgentBackground.jsx";
import SeasonBackgroundName from "@/game-val/components/SeasonBackgroundName.jsx";
import { isPrivateProfile } from "@/game-val/profile-utils.mjs";
import {
  AgentBackgroundCSS,
  AgentWinRateCSS,
  Container,
  DamageDealtCSS,
  FirstBloodCSS,
  HeaderCSS,
  HeadshotCSS,
  HoursPlayedCSS,
  KDACSS,
  MAPIconCSS,
  WeaponBackgroundCSS,
} from "@/game-val/SeasonReview.style.jsx";
import {
  useActStats,
  useAgentStats,
  useMapStats,
  useWeaponStats,
} from "@/game-val/use-act-review-data.mjs";
import {
  getActOptions,
  getHSLColor,
  getNameTag,
  separateNameTag,
} from "@/game-val/utils.mjs";
import Kills from "@/inline-assets/fn-kills.svg";
import StatCrit from "@/inline-assets/stat-crit.svg";
import StatWeapon from "@/inline-assets/stat-weapon.svg";
import TimerClock from "@/inline-assets/timer-clock.svg";
import Trophy from "@/inline-assets/trophy.svg";
import bodyTarget from "@/inline-assets/val-body-target.webp";
import BombMode from "@/inline-assets/val-bomb-mode.svg";
import {
  Callout,
  Callouts,
  Footer,
  Header,
  Section,
} from "@/shared/SeasonReview";
import { calcRate, displayRate } from "@/util/helpers.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function SeasonReview() {
  const { t } = useTranslation();
  const {
    parameters: [profileId, actId],
  } = useRoute();

  const {
    val: {
      profiles,
      content: { acts },
      cms: { agents },
    },
  } = useSnapshot(readState);

  // @ts-ignore
  const actOptions = getActOptions(acts);
  const act = actOptions.find((a) => a.key === actId);

  const profile: ValorantProfile | undefined =
    profiles[profileId.toLowerCase()];
  const queue = "overall";
  const actStats = useActStats(profile?.puuid, actId);

  const {
    headshotPercent,
    winRate,
    matchesPlayed,
    matchesWon,
    minutesPlayed,
    competitiveMatchesWon,
    hoursPlayed,
    actDuration,
    playDuration,
    damageDealt,
    kda,
    kills,
    firstBlood,
  } = actStats || {};

  const {
    agentsPlayed,
    topAgentMeta,
    topAgentStats,
    agentKDAGraph,
    agentWinRateGraph,
    agentHoursPlayedGraph,
  } = useAgentStats(profile?.puuid, actId, queue);
  const {
    highestKDAMap,
    mostWinsMap,
    highestKDAMapImg,
    mostPlayedMapImg,
    mostWinsMapImg,
  } = useMapStats(profile?.puuid, actId, queue);
  const {
    topWeaponMeta,
    topWeapon,
    topWeaponDamage,
    topWeaponKillRange,
    topWeaponHeadshotPercent,
    weaponDamageGraph,
    topFirstBloodWeaponMeta,
    weaponKillsGraph,
  } = useWeaponStats(profile?.puuid, actId, queue);

  const profileError = profile instanceof Error ? profile : !profileId;
  const isPrivate = isPrivateProfile(profile);
  const isPermitted = !isPrivate;

  const defaultAgent = "cypher";
  const defaultAgentImage = agents.find((a) => a.key === defaultAgent)?.images
    .hero.url;

  if (!profile) {
    return (
      <Container>
        <Header
          name={undefined}
          image={defaultAgentImage}
          borderImage={undefined}
          className={HeaderCSS()}
          imageClassName={undefined}
          borderImageClassName={undefined}
          backgroundImage={`${appURLs.CDN}/blitz/val/val-review-background.webp`}
          backgroundStack={<SeasonBackgroundName />}
          aboveName={
            <p className="loading">
              {t(
                "val:seasonReview.pleaseWait",
                "Please wait while your recap is loading",
              )}
            </p>
          }
        />
      </Container>
    );
  }

  if (!isPermitted) {
    return (
      <Container>
        <Header
          name={undefined}
          image={defaultAgentImage}
          borderImage={undefined}
          className={HeaderCSS()}
          imageClassName={undefined}
          borderImageClassName={undefined}
          backgroundImage={`${appURLs.CDN}/blitz/val/val-review-background.webp`}
          backgroundStack={<SeasonBackgroundName />}
          aboveName={
            <div className="cannot-record">
              <p className="type-page-header shade0">
                {t("val:privateAccount", "Private Account")}
              </p>
              <p>
                {t(
                  "val:seasonReview.weCannotRecord",
                  "We cannot record matches and stats for this profile.",
                )}
              </p>
            </div>
          }
        />
      </Container>
    );
  }

  if (profileError || !actStats) {
    return (
      <Container>
        <Header
          name={undefined}
          image={defaultAgentImage}
          borderImage={undefined}
          className={HeaderCSS()}
          imageClassName={undefined}
          borderImageClassName={undefined}
          backgroundImage={`${appURLs.CDN}/blitz/val/val-review-background.webp`}
          backgroundStack={<SeasonBackgroundName />}
          aboveName={
            <div className="cannot-record">
              <p className="type-page-header shade0">
                {t("val:accountNotFound", "Account Not Found")}
              </p>
              <p>
                {t(
                  "val:seasonReview.weCannotRecord",
                  "We cannot record matches and stats for this profile.",
                )}
              </p>
              <p>
                {t(
                  "val:seasonReview.downloadBlitz",
                  "Download Blitz and see you next year.",
                )}
              </p>
              {!IS_APP && (
                <DownloadButtonWrapper className="desktop-only" target="_blank">
                  {t("common:downloadBlitz", "Download Blitz")}
                </DownloadButtonWrapper>
              )}
            </div>
          }
        />
      </Container>
    );
  }

  return (
    <Container>
      <Header
        name={profile.gameName}
        image={topAgentMeta && topAgentMeta.images.hero.url}
        borderImage={undefined}
        className={HeaderCSS()}
        imageClassName={undefined}
        borderImageClassName={undefined}
        backgroundImage={`${appURLs.CDN}/blitz/val/val-review-background.webp`}
        backgroundStack={<SeasonBackgroundName names={[profile.gameName]} />}
        aboveName={t(
          "val:seasonReview.welcomeToRecap",
          "Welcome to your {{ actName }} recap",
          {
            actName: act.short.toUpperCase(),
          },
        )}
      />
      {highestKDAMap && (
        <Section
          id={"overview"}
          titleHighlightColor="var(--turq)"
          title={
            <Trans
              i18nKey="val:seasonReview.youPlayedAndWon"
              defaults="You played <span>{{matchesPlayed}}</span> games and won <span>{{matchesWon}}</span> of them!"
              values={{
                matchesPlayed: getLocaleString(matchesPlayed),
                matchesWon: getLocaleString(matchesWon),
              }}
              components={{ span: <span /> }}
            />
          }
          superTitle={t("common:navigation.overview", "Overview")}
          subTitle={
            <span>
              <Trans
                i18nKey="val:seasonReview.youPlayedFor"
                defaults="You played for <span>{{minutesPlayed}}</span> minutes, or <span>{{hoursPlayed}}</span> hours."
                values={{
                  minutesPlayed: getLocaleString(minutesPlayed, {
                    maximumFractionDigits: 0,
                  }),
                  hoursPlayed: getLocaleString(hoursPlayed, {
                    maximumFractionDigits: 0,
                  }),
                }}
                components={{ span: <span className="type-title--bold" /> }}
              />
              &nbsp;
              <DurationPerDay
                milliseconds={playDuration}
                totalMillis={actDuration}
              />
            </span>
          }
          backgroundImage={mostPlayedMapImg}
        >
          <Callouts>
            <Callout
              superTitle={t("common:stats.damageDealt", "Damage Dealt")}
              title={damageDealt}
              icon={<StatCrit />}
              iconColor="var(--yellow)"
              barColor="var(--yellow)"
              className={DamageDealtCSS()}
              data={weaponDamageGraph}
            />
            <Callout
              superTitle={t("common:stats.kda", "KDA")}
              title={kda}
              icon={<StatWeapon />}
              iconColor="var(--orange)"
              barColor="var(--orange)"
              className={KDACSS()}
              data={agentKDAGraph}
            />
            <Callout
              superTitle={t("val:seasonReview.highestKDA", "Highest KDA")}
              title={
                <Trans
                  i18nKey="val:seasonReview.highestKDAOnMap"
                  defaults="{{KDA}} <span>on {{gameMap}}</span>"
                  values={{
                    KDA: getLocaleString(
                      calcRate(
                        highestKDAMap?.kills + highestKDAMap?.assists,
                        highestKDAMap?.deaths,
                      ),
                      { maximumFractionDigits: 2 },
                    ),
                    gameMap: highestKDAMap?.gameMap?.name,
                  }}
                  components={{ span: <span /> }}
                />
              }
              image={highestKDAMapImg}
              imageClassName={MAPIconCSS()}
            />
            <Callout
              superTitle={t("val:seasonReview.mostWins", "Most Wins")}
              title={
                <Trans
                  i18nKey="val:seasonReview.mostWinsOnMap"
                  defaults="{{matchesWon}} <span>on {{gameMap}}</span>"
                  values={{
                    matchesWon: getLocaleString(mostWinsMap?.matchesWon),
                    gameMap: mostWinsMap?.gameMap?.name,
                  }}
                  components={{ span: <span /> }}
                />
              }
              image={mostWinsMapImg}
              imageClassName={MAPIconCSS()}
            />
            <Callout
              superTitle={t("val:seasonReview.matchesWon", "Matches Won")}
              title={
                <Trans
                  i18nKey="val:seasonReview.matchesWonInComp"
                  defaults="{{competitiveMatchesWon}} <span>in Competitive</span>"
                  values={{
                    competitiveMatchesWon: getLocaleString(
                      competitiveMatchesWon,
                    ),
                  }}
                  components={{ span: <span /> }}
                />
              }
              icon={<BombMode />}
            />
          </Callouts>
        </Section>
      )}
      {topAgentMeta && (
        <Section
          id="your-agent"
          superTitle={t("val:seasonReview.yourAgent", "Your Agent")}
          title={
            <Trans
              i18nKey="val:seasonReview.mostPlayedAgent"
              defaults="<span>{{agent}}</span> is your most played agent this act"
              values={{ agent: topAgentMeta.name }}
              components={{ span: <span /> }}
            />
          }
          titleHighlightColor={`hsl(${getHSLColor(topAgentMeta.color)})`}
          subTitle={
            <Trans
              i18nKey="val:seasonReview.agentPicked"
              defaults="This agent has been picked on <span>{{matchesPlayed}}</span> matches, or <span>{{hoursPlayed}}</span> hours. You played a total of <span>{{agentsPlayed}}</span> Agents and your favorite role is <span>{{role}}</span>."
              values={{
                matchesPlayed: getLocaleString(topAgentStats.matchesPlayed),
                hoursPlayed: getLocaleString(
                  topAgentStats.playDuration / (1000 * 60 * 60),
                  { maximumFractionDigits: 0 },
                ),
                agentsPlayed: getLocaleString(agentsPlayed),
                role: topAgentMeta.class,
              }}
              components={{
                span: <span className="type-title--bold" />,
              }}
            />
          }
          backgroundImage={topAgentMeta.images.fullSizeConcept.url}
          backgroundStack={
            <SeasonAgentBackground
              backgroundColor={`hsl(${getHSLColor(topAgentMeta.color)}`}
            />
          }
          className={AgentBackgroundCSS()}
        >
          <Callouts>
            <Callout
              superTitle={t("common:stats.winRate", "Win Rate")}
              title={winRate}
              icon={<Trophy />}
              iconColor="var(--green)"
              barColor="var(--green)"
              data={agentWinRateGraph}
              className={AgentWinRateCSS()}
            />
            <Callout
              superTitle={t("common:stats.hoursPlayed", "Hours played")}
              title={t("val:stats.hoursPlayed", "{{ hours }} hours", {
                hours: getLocaleString(
                  topAgentStats.playDuration / (1000 * 60 * 60),
                  { maximumFractionDigits: 1 },
                ),
              })}
              icon={<TimerClock />}
              iconColor="var(--purple)"
              barColor="var(--purple)"
              data={agentHoursPlayedGraph}
              className={HoursPlayedCSS()}
            />
          </Callouts>
        </Section>
      )}
      {topWeapon && (
        <Section
          id="your-frag"
          superTitle={t("val:seasonReview.yourFrag", "Your Frag")}
          title={
            <Trans
              i18nKey="val:seasonReview.mostPlayedWeapon"
              defaults="<span>{{topWeaponMetaName}}</span> is your main weapon with <span>{{topWeaponKills}}</span> kills"
              values={{
                topWeaponMetaName: topWeaponMeta.name,
                topWeaponKills: getLocaleString(topWeapon.kills),
              }}
              components={{ span: <span /> }}
            />
          }
          titleHighlightColor="var(--purple)"
          subTitle={
            <Trans
              i18nKey="val:sesonReview.averageWeaponDamage"
              defaults="On average, you deal <span>{{topWeaponDamage}}</span> damage per match with <span>{{topWeaponMetaName}}</span> at <span>{{topWeaponKillRange}}m</span> on average. You're pretty accurate with <span>{{topWeaponHeadshotPercent}}</span> of headshots."
              values={{
                topWeaponDamage: getLocaleString(topWeaponDamage),
                topWeaponMetaName: topWeaponMeta.name,
                topWeaponKillRange: getLocaleString(topWeaponKillRange),
                topWeaponHeadshotPercent: displayRate(
                  topWeaponHeadshotPercent,
                  100,
                ),
              }}
              components={{
                span: <span className="type-title--bold" />,
              }}
            />
          }
          backgroundImage={topWeaponMeta.svgImage.url}
          backgroundStack={
            <SeasonAgentBackground backgroundColor="var(--purple)" />
          }
          className={WeaponBackgroundCSS()}
        >
          <Callouts>
            {topFirstBloodWeaponMeta ? (
              <Callout
                superTitle={t("val:stats.firstBloods", "First Bloods")}
                title={firstBlood}
                image={topFirstBloodWeaponMeta.svgImage.url}
                imageClassName={FirstBloodCSS()}
              />
            ) : null}
            <Callout
              superTitle={t("common:headshot", "Headshot")}
              title={displayRate(headshotPercent, 100)}
              image={bodyTarget}
              className={HeadshotCSS()}
            />
            <Callout
              superTitle={t("common:stats.kills", "Kills")}
              title={kills}
              icon={<Kills />}
              iconColor="var(--turq)"
              barColor="var(--turq)"
              className={DamageDealtCSS()}
              data={weaponKillsGraph}
            />
          </Callouts>
        </Section>
      )}
      <Footer
        hideRegion
        currentRegion={undefined}
        regions={[]}
        linkConstructor={(_, name) => `/valorant/act-review/${name}/${actId}`}
      />
    </Container>
  );
}

export default SeasonReview;

SeasonReview.fullBleed = true;

export function meta(_params): Meta {
  const [profileId] = _params;
  const { name, tag } = separateNameTag(profileId);
  const nmaeTag = getNameTag(name, tag);
  const title: [string, string, Record<string, unknown>?] = [
    "val:seasonReview.title",
    "{{nmaeTag}}'s Act Review",
    { nmaeTag },
  ];
  const description: [string, string, Record<string, unknown>?] = [
    "val:seasonReview.description",
    "Explore your stats over the last act",
  ];

  return {
    title,
    description,
  };
}
